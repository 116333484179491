/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



body {
    color: #ffffff;
}


html {
    font-size: 12px; /* font-size 1em = 10px on default browser settings */
  }

 
 //remover as setinhas cima/baixo automáticas do browser em inputs do tipo 'number'
  input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}



ion-header{
    ion-toolbar{
        --background:var(--ion-color-light-tint);
        --min-height:1cm;
    }
}

ion-content{
    --background: var(--ion-color-light-tint);
    --padding-top:15px;
    --padding-start:15px;
    --padding-end: 15px;
}

ion-grid{
    width: 100%;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    margin-bottom: 18px;
    display: inline-table;
    ion-row{
        padding:0px;
        margin:0px;
        ion-col {
            margin:0px;
            padding:0px;
            h1{
                padding:0px;
                margin:0px;
                color: var(--ion-color-primary);
                margin-bottom:15px;
            }
            ion-label{
                text-align: right;
                font-size: 1.2rem; 
                margin:0px 5px 5px 0px;
                font-weight: 400;
                width: 35%;
                display: block;
                float: left;
            }
            ion-text{
                color: var(--ion-color-primary-shade);
                font-size: 1.2rem; 
                margin:0px 5px 5px 0px;
                font-weight: 600;
                text-align:left;
                display: block;
            }
        }
    }
    h1{
        border-radius: 8px 8px 4px 4px;
        padding:5px 5px 5px 10px;
        border-top: var(--ion-color-primary) solid 3px;
        color: var(--ion-color-primary);
        margin:-15px;
        width: calc(100% + 30px); // twice the value of the parent's padding
        margin-bottom:15px;
        background-color: var(--ion-color-light-tint);
    }
}

ion-modal{
    --width:100%;
    --min-width:100%;
    --margin-bottom: 35px;
    padding-bottom: 35px;
    ion-header{
        ion-toolbar{
            border-top: 3px solid var(--ion-color-primary-tint);
            --background:var(--ion-color-light-tint);
            --min-height:1cm;
            height: 45px;
            float: left;
            ion-buttons{
                float: right;
                text-align: right;
                ion-button{
                    background-color: var(--ion-color-primary);
                    color: var(--ion-color-primary-contrast);
                    border-radius:4px;
                    box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
                }
            }
        }
    }
}

ion-input{
    
    border-bottom: solid 1px var(--ion-color-primary);
}

table{
    background-color: rgba(var(--ion-color-primary-rgb), 0.10);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    border: solid var(--ion-color-primary-shade);
    border-width: 2px 2px 2px 2px;
    width: 100%;
    min-width: 664px;
    table-layout: fixed;
    text-align: center;
    div{
        display: content;
    }
    thead{
        background: var(--ion-color-primary-tint);
        tr{
            th{
                border: 1px solid var(--ion-color-primary-shade);
                font-size: 1.2rem;
                font-weight: normal;
                color: var(--ion-color-primary-contrast);
                text-align: left;
                padding: 2px 4px;
                white-space: nowrap;
            }
        }
    }
    tbody{
        tr{
            td{
                padding: 2px 4px;
                font-size: 1rem; 
                text-align: left; 
                border:1px solid var(--ion-color-primary-tint)
            }
        }
    }
    // inner table
    table{
        margin-top: 3px;
        margin-left: 17px;
        margin-bottom: 10px;
        width: calc(100% - 30px);
        thead{
            background-color: rgba(var(--ion-color-primary-rgb), 0.30);
            
            
        tr{
            color: #FFFFFF;
            line-height: '1px';
            th{
                color: var(--ion-color-primary);
                font-size: 1rem;
                font-weight: 700; 
                text-align: left; 
                
                margin:2px;
                padding:2px 4px;
            }
        }
        }
    }
}

.modal-wrapper {
    --width: 70%;
    --height: 91%;
}
//usado para fazer a alça da tabela detalhe na tabela master
// td > table:before{
//     content:'';
//     position:absolute;
//     width: 13px;
//     height: 35px;
//     top: 43px;
//     left: 37px;
//     transform: translateX(-100%);
//     border:2px dotted;
//     border-top:  none;
//     border-right: none;
//     border-color: var(--ion-color-medium)    
//   }